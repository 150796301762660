import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-monsters"></a><h2>Epic Monsters</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">A-Z Index</span></h2>
    <table className="monster-index" border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="top">
          <td style={{
            "width": "12%"
          }}><a href="#ABOMINATION" style={{
              "color": "rgb(87, 158, 182)"
            }}>Abomination</a>
            <p>{`—`}<a href="#anaxim" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Anaxim`}</a></p>
            <p>{`—`}<a href="#atropal" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Atropal`}</a></p>
            <p>{`—`}<a href="#chichimec" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Chichimec`}</a></p>
            <p>{`—`}<a href="#dream-larva" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Dream Larva`}</a></p>
            <p>{`—`}<a href="#hecatoncheires" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Hecatoncheires`}</a></p>
            <p>{`—`}<a href="#infernal" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Infernal`}</a></p>
            <p>{`—`}<a href="#phaethon" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Phaethon`}</a></p>
            <p>{`—`}<a href="#phane" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Phane`}</a></p>
            <p>{`—`}<a href="#xixecal" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Xixecal`}</a></p>
          </td>
          <td style={{
            "width": "12%"
          }}><a href="epicNonAbominations.html#behemoth" style={{
              "color": "rgb(87, 158, 182)"
            }}>Behemoth</a>
            <a href="epicNonAbominations.html#brachyurus" style={{
              "color": "rgb(87, 158, 182)"
            }}>Brachyurus</a>
            <a href="epicNonAbominations.html#colossus" style={{
              "color": "rgb(87, 158, 182)"
            }}>Colossus</a>
            <a href="epicNonAbominations.html#demilich" style={{
              "color": "rgb(87, 158, 182)"
            }}>Demilich</a>
            <a href="epicNonAbominations.html#devastation-vermin" style={{
              "color": "rgb(87, 158, 182)"
            }}>Devastation Vermin</a>
            <a href="epicNonAbominations.html#dragon-advanced" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dragon, Advanced</a>
            <a href="epicNonAbominations.html#dragon-epic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dragon, Epic</a>
            <p>{`—`}<a href="epicNonAbominations.html#force-dragon" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Force Dragon`}</a></p>
            <p>{`—`}<a href="epicNonAbominations.html#prismatic-dragon" style={{
                "color": "rgb(87, 158, 182)"
              }}>{`Prismatic Dragon`}</a></p>
          </td>
          <td style={{
            "width": "12%"
          }}><a href="epicNonAbominations.html#elemental-primal" style={{
              "color": "rgb(87, 158, 182)"
            }}>Elemental, Primal</a>
            <a href="epicNonAbominations.html#genius-loci" style={{
              "color": "rgb(87, 158, 182)"
            }}>Genius Loci</a>
            <a href="epicNonAbominations.html#gibbering-orb" style={{
              "color": "rgb(87, 158, 182)"
            }}>Gibbering Orb</a>
            <a href="epicNonAbominations.html#gloom" style={{
              "color": "rgb(87, 158, 182)"
            }}>Gloom</a>
            <a href="epicNonAbominations.html#golem" style={{
              "color": "rgb(87, 158, 182)"
            }}>Golem</a>
            <a href="epicNonAbominations.html#ha-naga" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ha-Naga</a>
            <a href="epicNonAbominations.html#hagunemnon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hagunemnon (protean)</a>
            <a href="epicNonAbominations.html#hoary-hunter" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hoary Hunter</a>
            <a href="epicNonAbominations.html#hunefer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hunefer</a>
          </td>
          <td style={{
            "width": "12%"
          }}><a href="epicNonAbominations.html#lavawight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Lavawight</a>
            <a href="epicNonAbominations.html#legendary-animal" style={{
              "color": "rgb(87, 158, 182)"
            }}>Legendary Animal</a>
            <a href="epicNonAbominations.html#leshay" style={{
              "color": "rgb(87, 158, 182)"
            }}>Leshay</a>
            <a href="epicNonAbominations.html#living-vault" style={{
              "color": "rgb(87, 158, 182)"
            }}>Living Vault</a>
            <a href="epicNonAbominations.html#mercane" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mercane</a>
            <a href="epicNonAbominations.html#mu-spore" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mu Spore</a>
            <a href="epicNonAbominations.html#neh-thalggu" style={{
              "color": "rgb(87, 158, 182)"
            }}>Neh-Thalggu</a> 
            <div style={{
              "marginLeft": "40px"
            }}><a href="epicNonAbominations.html#neh-thalggu">(Brain Collector)</a>
            </div>
            <a href="epicNonAbominations.html#paragon-creature" style={{
              "color": "rgb(87, 158, 182)"
            }}>Paragon Creature</a></td>
          <td style={{
            "width": "12%"
          }}><a href="epicNonAbominations.html#prismasaurus" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prismasaurus</a>
            <a href="epicNonAbominations.html#pseudonatural-troll" style={{
              "color": "rgb(87, 158, 182)"
            }}>Pseudonatural Troll</a>
            <a href="epicNonAbominations.html#ruin-swarm" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ruin Swarm</a>
            <a href="epicNonAbominations.html#shadow-of-the-void" style={{
              "color": "rgb(87, 158, 182)"
            }}>Shadow of the Void</a>
            <a href="epicNonAbominations.html#shape-of-fire" style={{
              "color": "rgb(87, 158, 182)"
            }}>Shape of Fire</a>
            <a href="epicNonAbominations.html#sirrush" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sirrush</a>
            <a href="epicNonAbominations.html#tayellah" style={{
              "color": "rgb(87, 158, 182)"
            }}>Tayellah</a>
            <a href="epicNonAbominations.html#thorciasid" style={{
              "color": "rgb(87, 158, 182)"
            }}>Thorciasid</a>
            <a href="epicNonAbominations.html#titan-elder" style={{
              "color": "rgb(87, 158, 182)"
            }}>Titan, Elder</a>
          </td>
          <td style={{
            "width": "12%"
          }}><a href="epicNonAbominations.html#treant-elder" style={{
              "color": "rgb(87, 158, 182)"
            }}>Treant, Elder</a>
            <a href="epicNonAbominations.html#umbral-blot" style={{
              "color": "rgb(87, 158, 182)"
            }}>Umbral Blot (Blackball)</a>
            <a href="epicNonAbominations.html#uvuudaum" style={{
              "color": "rgb(87, 158, 182)"
            }}>Uvuudaum</a>
            <a href="epicNonAbominations.html#vermiurge" style={{
              "color": "rgb(87, 158, 182)"
            }}>Vermiurge</a>
            <a href="epicNonAbominations.html#winter-wight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Winter Wight</a>
            <a href="epicNonAbominations.html#worm-that-walks" style={{
              "color": "rgb(87, 158, 182)"
            }}>Worm that Walks</a>
          </td>
        </tr>
      </tbody>
    </table>
    <h3><a id="ABOMINATION"></a>ABOMINATION</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a id="building-abominations"></a><h5>BUILDING ABOMINATIONS </h5>
Abominations are a grouping of the <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#outsider">outsider</a> type in the same way
that
demons are a grouping of the outsider type. The accompanying table
indicates the average values an abomination of a given size might have
for its basic physical scores. Abominations also share several other
characteristics.
    <a id="table-abominations-by-size"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Size
          </th>
          <th style={{
            "width": "50px"
          }}>Str</th>
          <th style={{
            "width": "50px"
          }}>Dex</th>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold",
            "width": "50px"
          }}>Con</td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold",
            "width": "100px"
          }}># of HD</td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold",
            "width": "40px"
          }}>Slam</td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold",
            "width": "40px"
          }}>Bite</td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold",
            "width": "40px"
          }}>Claw</td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold",
            "width": "40px"
          }}>Gore</td>
        </tr>
        <tr className="odd-row">
          <td>Fine 
          </td>
          <td style={{
            "width": "50px"
          }}>12-13
          </td>
          <td style={{
            "width": "50px"
          }}>26-27
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "50px"
          }}>12-13
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>4d8-9d8
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>-
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d6
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d4
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d4
          </td>
        </tr>
        <tr>
          <td>Diminuitive
          </td>
          <td style={{
            "width": "50px"
          }}>14-15
          </td>
          <td style={{
            "width": "50px"
          }}>24-25
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "50px"
          }}>14-15
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>7d8-13d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d4
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d8
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d6
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d6
          </td>
        </tr>
        <tr className="odd-row">
          <td>Tiny
          </td>
          <td style={{
            "width": "50px"
          }}>18-19
          </td>
          <td style={{
            "width": "50px"
          }}>22-23
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "50px"
          }}>16-17
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>10d8-21d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d6
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>2d6
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d8
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d8
          </td>
        </tr>
        <tr>
          <td>Small
          </td>
          <td style={{
            "width": "50px"
          }}>26-27
          </td>
          <td style={{
            "width": "50px"
          }}>20-21
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "50px"
          }}>20-21
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>19d8-33d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>1d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>2d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>2d6</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>2d6</td>
        </tr>
        <tr className="odd-row">
          <td>Medium-size
          </td>
          <td style={{
            "width": "50px"
          }}>34-35
          </td>
          <td style={{
            "width": "50px"
          }}>18-19
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "50px"
          }}>24-25
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>27d8-38d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>2d6</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>4d6</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>2d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>2d8</td>
        </tr>
        <tr>
          <td>Large
          </td>
          <td style={{
            "width": "50px"
          }}>42-43
          </td>
          <td style={{
            "width": "50px"
          }}>16-17
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "50px"
          }}>28-29
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>36d8-50d8+</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>2d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>4d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>4d6</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>4d6</td>
        </tr>
        <tr className="odd-row">
          <td>Huge
          </td>
          <td style={{
            "width": "50px"
          }}>50-51
          </td>
          <td style={{
            "width": "50px"
          }}>14-15
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "50px"
          }}>32-33
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>47d8-58d8+</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>4d6</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>8d6</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>4d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>4d8</td>
        </tr>
        <tr>
          <td>Gargantuan
          </td>
          <td style={{
            "width": "50px"
          }}>58-59
          </td>
          <td style={{
            "width": "50px"
          }}>12-13
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "50px"
          }}>36-37
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>56d8-70d8+</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>4d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>8d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>8d6</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>8d6</td>
        </tr>
        <tr className="odd-row">
          <td>Colossal
          </td>
          <td style={{
            "width": "50px"
          }}>66-67
          </td>
          <td style={{
            "width": "50px"
          }}>10-11
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "50px"
          }}>40-41
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "100px"
          }}>71d8+</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>8d6</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>16d6</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>8d8</td>
          <td style={{
            "verticalAlign": "top",
            "width": "40px"
          }}>8d8</td>
        </tr>
      </tbody>
    </table>
    <a id="abomination-traits"></a><h6>Abomination Traits</h6>
    <p>All abominations are born directly (or indirectly) from a god
and some lesser creature (or idea), but none are favored, wanted, or
loved. Still, they all share a tiny spark of deific energy, which
grants them the qualities described in below. (Note: deity rules are
used, abominations are rank 0 deities.) </p>
    <a id="abomination-immunities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Immunities (Ex):</span>
Abominations are immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, or any
form-altering attack. They are not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>. They
are immune to mind-affecting effects
(charms, compulsions, phantasms, patterns, and morale effects), and are
immune to one of five energy types (specific to the abomination). </p>
    <a id="abomination-resistances"></a><p><span style={{
        "fontWeight": "bold"
      }}>Resistances (Ex):</span>
Abominations have <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a> 20, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">cold resistance</a> 20,
and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a> of at least 10/epic (certain abominations have higher
damage
reduction). Abominations all have significant <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#SR">spell
resistance</a>.
Abominations resist detection, and are all treated as if affected by a <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>
spell of a caster
level equal to the abomination&#8217;s HD. </p>
    <a id="abomination-special-qualities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Special Qualities (Ex):</span>
All abominations have the spell-like ability to use <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true
seeing</a> at
will. Abominations are not subject to death from massive damage, and
they have maximum hit points per Hit Die. Abominations have the <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
extraordinary
ability to a range of 500 feet. Abominations
can choose both nonepic and epic feats as part of their feat selection.
    </p>
    <a id="abomination-telepathy"></a><p><span style={{
        "fontWeight": "bold"
      }}>Telepathy (Su):</span>
Abominations can communicate telepathically with any creature within
1,000 feet that has a language. </p>
    <a id="abomination-summon-creature"></a><p><span style={{
        "fontWeight": "bold"
      }}>Summon Creature (Sp):</span>
Abominations can summon creatures associated either with the portfolio
of their godly progenitor or with their imprisonment. Summoned
creatures serve the abomination without question. Summoned creatures
automatically return whence they came after 1 hour, or sooner if slain.
See specific abomination entries for additional details on summoning. </p>
    <a id="abomination-fast-healing-and-regeneration"></a><p><span style={{
        "fontWeight": "bold"
      }}>Fast Healing and Regeneration:</span>
Most abominations have some measure of <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#fast-healing">fast
healing</a> and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#regeneration">regeneration</a>,
ranging from 5 to 55 for both abilities. Usually, higher HD
abominations have higher values for fast healing and regeneration,
though this not always the case. If an abomination has regeneration, it
is usually subject to normal damage from at least two sources, one of
which is the antithesis for their subtype, and the other of which is
somehow tied to some unique feature of the abomination. </p>
    <a id="abomination-natural-armor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Natural Armor:</span> All
abominations have some degree of natural armor bonus ranging from +10
to +100. Usually, higher HD abominations have higher natural armor
bonuses, but this is not always the case. </p>
    <a id="abomination-spell-like-abilities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
All abominations have access to a variety of spells in the form of
spell-like abilities, which they can use as 20th-or higher-level
casters, depending on the abomination. No rule governs how many or how
few spell-like abilities an abomination may claim. </p>
    <a id="abomination-unique-abilities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Unique Abilities:</span> All
abominations have at least one or more unique abilities tied to their
godly parent&#8217;s portfolio, or to the manner of their incarceration. </p>
    <a id="abomination-spell-resistance"></a><p><span style={{
        "fontWeight": "bold"
      }}>Spell Resistance:</span> As a
general rule of thumb, abominations have <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#SR">spell
resistance</a> equal to
their CR +12.
      <p>{`Abominations speak Abyssal, Celestial, Infernal, and often a language
associated with their imprisonment.`}</p>
    </p>
    <h3><a id="anaxim"></a>ANAXIM</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Medium-Size <a style={{
        "color": "black"
      }} href="monsterTypes.html#construct">Construct</a>
(<a style={{
        "color": "black"
      }} href="monsterTypes.html#extraplanar">Extraplanar</a>,
      <a style={{
        "color": "black"
      }} href="monsterTypes.html#lawful">Lawful</a>)</span>
&nbsp;&nbsp;&nbsp;
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Dice:</span> 38d10 (420
hp) &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Initiative:</span> +7 (Dex)
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Speed:</span> 60 ft., fly 200
ft. (perfect) &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>AC:</span> 37 (+7 Dex, +20
natural) &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Base Attack/Grapple:</span>
+28/+40&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Attack:</span> Spinning blade
+43 (2d6+12/19-20 (plus 1d6 on critical)) or electricity ray +35 ranged
touch&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Full Attack:</span> 2 spinning
blades +43 (2d6+12/19-20(plus 1d6 on critical)) melee, 2 slams +35
melee (2d6+6), shocking touch +35 melee touch (2d6+6); or electricity
ray +35 ranged touch (10d6 electricity), 6 spikes +30 ranged (2d6+12)
(120 ft. range increment)&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Space/Reach:</span> 5 ft./5 ft.
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Attacks: </span>Rend
4d6+18, sonic blast, spell-like abilities, summon <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersG.html#iron-golem">iron
golem</a>
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Qualities: </span>Abomination
traits, magic immunity, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#construct">construct traits</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#fast-healing">fast
healing</a> 15, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#SR">SR</a> 34, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a> 10/chaotic and epic and adamantine&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Skills: </span><a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#climb">Climb</a>
+53, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
+65&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Feats: </span><a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#blind-fight">Blind-Fight</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#cleave">Cleave</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#great-cleave">Great Cleave</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-bull-rush">Improved Bull Rush</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-critical">Improved Critical</a> (blade), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-initiative">Improved Initiative</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-sunder">Improved
Sunder</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#power-attack">Power Attack</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#weapon-focus">Weapon
Focus</a>
(blade)&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Epic Feats: </span><a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#devastating-critical">Devastating
Critical</a> (blade), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-toughness">Epic Toughness</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a> (blade),
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#overwhelming-critical">Overwhelming Critical</a>
(blade)&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Saves:</span> Fort +12, Ref +19,
Will +17 &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Abilities:</span> Str 35, Dex
25, Con -, Int 10, Wis 20, Cha 20 &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Climate/Terrain:</span> Any
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Organization: </span>Solitary,
binary (pair), or command line (2-5 anaxims and 5-12 iron golems)
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Challenge Rating:</span> 22
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Treasure: </span>Standard
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Alignment: </span>Always lawful
neutral &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Advancement: </span>39-48 HD
(Large); 49-55 HD (Huge); 56-70 HD (Gargantuan); 71-140 HD
(Colossal)&nbsp;&nbsp; 
    </p>
    <p>An anaxim&#8217;s natural weapons are treated as epic and
lawful-aligned for the purpose of overcoming <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a>.</p>
    <h6>COMBAT </h6>
    <p><span style={{
        "fontWeight": "bold"
      }}>Sonic Blast (Ex):</span> As a
standard action, an anaxim can emit a 60-foot cone of sonic energy that
deals 20d6 points of sonic damage to all creatures that fail a Reflex
save (DC 29); those that succeed take half damage.
      <p>{`The save DC is Constitution-based`}</p></p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Rend (Ex):</span> If the
anaxim hits with both spinning blades, it slices particularly well.
This attack automatically deals an additional 4d6+18 points of damage. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
Caster level 22nd. The save DCs
are Charisma-based.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#greater-dispel-magic">greater dispel magic</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#displacement">displacement</a>
(DC 18), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#greater-invisibility">greater
invisibility</a> (DC 19), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#ethereal-jaunt">ethereal jaunt</a>. 
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon Iron Golem (Sp):</span>
An anaxim can summon an <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersG.html#iron-golem">iron golem</a> up to four times per
day. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abomination Traits:</span>
Immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, and
other form-altering attacks;
not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, or
death
from massive damage; immune to mind-affecting effects; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a>
20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">cold resistance</a> 20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>;
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> at will; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
500 ft.; telepathy out to 1,000 ft. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Construct Traits:</span>
Immune to mind-affecting effects (charms, compulsions, phantasms,
patterns, and morale effects), and to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#poison">poison</a>, sleep, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#paralyzed">paralysis</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#stunned">stunning</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#disease">disease</a>, death effects,
necromantic effects, and any effect
that requires a Fortitude save unless it also works on objects. Cannot
heal damage (though <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#regeneration">regeneration</a> and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#fast-healing">fast
healing</a> still apply, if
present). Not subject to critical hits, subdual damage, ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>.
Not at risk of death from massive
damage, but destroyed when reduced to 0 hit points or less; cannot be
raised or resurrected. Darkvision 60 ft.
    </p>
    <h3><a id="atropal"></a>ATROPAL</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a style={{
      "fontWeight": "bold"
    }} href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery5a/44165_C5_atropal.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span style={{
      "fontWeight": "bold"
    }}>Large <a style={{
        "color": "black"
      }} href="monsterTypes.html#undead">Undead</a>
(<a style={{
        "color": "black"
      }} href="monsterTypes.html#evil">Evil</a>, <a style={{
        "color": "black"
      }} href="monsterTypes.html#extraplanar">Extraplanar</a>,
      <a style={{
        "color": "black"
      }} href="monsterTypes.html#lawful">Lawful</a>)
&nbsp;</span>&nbsp;&nbsp;
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Dice:</span>&nbsp; 66d12
(792 hp) &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Initiative:</span>&nbsp; +6 (+2
Dex, +4 <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-initiative">Improved Initiative</a>)
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Speed:&nbsp;</span> 5 ft., fly
240 ft. (perfect) &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>AC:</span>&nbsp; 51 (-1 size, +2
Dex, +40 natural) &nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Base Attack /Grapple:</span>
+33/+53&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Attack:</span>&nbsp; Touch +49
(2d6 Con drain/19-20) melee touch&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Full Attack:</span>&nbsp; 2
touches +49 (2d6 Con drain/19-20) melee touch, eye ray +30 (negative
level damage/19-20) ranged touch &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Space/Reach:</span>&nbsp; 10
ft/10 ft. &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Attacks:</span>&nbsp;
Constitution <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#ability-score-loss">drain</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#energy-drain">energy
drain</a> (2d4 negative levels, Fort DC 59),
spell-like abilities, summon <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersMtoN.html#nightcrawler">nightcrawler</a> &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Qualities:</span>
Abomination traits, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#undead">undead traits</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#turn">rebuke/command
undead</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#regeneration">regeneration</a>
20, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#SR">SR</a>
42, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">DR</a>
15/good and epic and silver, negative energy aura
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Saves:</span> Fort +22, Ref +26,
Will +43 &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Abilities:</span>&nbsp; Str 43,
Dex 15, Con -, Int 28, Wis 22, Cha 42 &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Skills:</span>&nbsp; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#bluff">Bluff</a>
+85, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a> +85, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a>
+97, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a>
-2, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
+1, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#intimidate">Intimidate</a> +91, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a>
(arcana, history,
religion, the planes) +78, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> +77, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#search">Search</a>
+78, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense Motive</a> +75, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a>
+84, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>
+77&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Feats:</span>&nbsp; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#alertness">Alertness</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#cleave">Cleave</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#dodge">Dodge</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#expertise">Expertise</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#great-cleave">Great
Cleave</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-bull-rush">Improved Bull Rush</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-critical">Improved Critical</a> (touch), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-critical">Improved Critical</a> (eye
ray), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-initiative">Improved Initiative</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#iron-will">Iron
Will</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#lightning-reflexes">Lightning Reflexes</a>,
Mobility, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#power-attack">Power Attack</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterFeats.html#quicken-spell-like-ability">Quicken
Spell-Like Ability</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#run">Run</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#spring-attack">Spring Attack</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#weapon-focus">Weapon
Focus</a> (touch), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#weapon-focus">Weapon Focus</a> (eye ray), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#whirlwind-attack">Whirlwind
Attack</a> &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span>&nbsp; <span style={{
        "fontWeight": "bold"
      }}></span><a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#devastating-critical">Devastating
Critical</a> (touch), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#overwhelming-critical">Overwhelming Critical</a>
(touch), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#undead-mastery">Undead
Mastery</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#zone-of-animation">Zone of Animation</a>
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Climate/Terrain:</span>&nbsp;
Any &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Organization:</span>&nbsp;
Solitary &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Challenge Rating:</span>&nbsp;
30 &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Treasure:</span>&nbsp; Standard
&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Alignment:</span>&nbsp; Always
lawful evil &nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Advancement:</span>&nbsp; 67-80
HD (Large); 81-100 HD (Huge) &nbsp;&nbsp; 
    </p>
    <p>An atropal&#8217;s natural weapons are treated as epic, evil, and
lawful-aligned for the purpose of overcoming <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a>.</p>
    <h6>COMBAT </h6>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
Caster level 30th; save DC 26 + spell level. The save DCs are
Charisma-based
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsAtoB.html#animate-dead">animate
dead</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsAtoB.html#blasphemy">blasphemy</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsC.html#create-greater-undead">create greater undead</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsC.html#create-undead">create
undead</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsC.html#cone-of-cold">cone of cold</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#desecrate">desecrate</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#greater-dispel-magic">greater dispel magic</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#finger-of-death">finger of death</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#greater-invisibility">greater
invisibility</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsPtoR.html#plane-shift">plane shift</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#slay-living">slay
living</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#speak-with-dead">speak with dead</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#spectral-hand">spectral
hand</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#greater-teleport">greater teleport</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#unholy-aura">unholy
aura</a>; 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>5/day</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#haste">haste</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsPtoR.html#project-image">project image</a>,
weird.&nbsp;</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Rebuke/Command Undead (Su):</span>
Atropals can <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#turn">rebuke or command</a> undead as a
cleric with a level equal to
the atropal&#8217;s HD + 6.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Negative Energy Aura (Su):</span>
A 30-foot-radius spread negative energy aura spreads from each atropal.
All undead in the field (including the atropal) are treated as if
having <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#turn-resistance">turn resistance</a> +20 and a
negative energy version of <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#fast-healing">fast
healing</a> 20. Living creatures in the aura are treated as having ten <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drain">negative levels</a>
unless they have some sort of negative energy
protection or <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsPtoR.html#protection-from-evil">protection from evil</a>.
Creatures with 10 or fewer HD or
levels perish (and, at the atropal&#8217;s option, rise as <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersS.html#spectre">spectres</a>
under the
atropal&#8217;s command 1 minute later). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Constitution Drain (Su):</span>
When the atropal hits a living opponent with a touch attack, the
opponent takes 5 points of permanent Constitution <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or 10
points
on a critical hit. The atropal heals 20 points of damage, or 40 points
on a critical hit, whenever it drains Constitution, gaining any excess
as temporary hit points. These temporary hit points last a maximum of 1
hour. The attack allows a Fortitude save (DC 59). The DC is
Charisma-based.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Energy Drain (Su):</span>
When the atropal hits with a ranged touch attack (a ray of darkness
that it shoots from one eye to a range of 400 feet), the resultant <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>
bestows four <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drain">negative levels</a>,
or eight negative levels on
a critical hit. For each negative level bestowed on an opponent, the
atropal heals 10 points of damage, or 20 on a critical hit, gaining any
excess as temporary hit points. These temporary hit points last a
maximum of 1 hour. After 24 hours have passed, the afflicted opponent
must attempt a Fortitude save (DC 59) for each negative level. If
successful, the negative level goes away with no harm to the creature;
otherwise, the creature&#8217;s level decreases by one. The DC is
Charisma-based.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Regeneration (Ex):</span>
Atropals take normal damage from good weapons or sentient weapons (or
otherwise living weapons). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon Nightcrawler (Sp):</span>
Five times per day an atropal can summon a <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersMtoN.html#nightcrawler">nightcrawler</a>.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abomination Traits:</span>
Immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, and
other form-altering attacks;
not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, or
death
from massive damage; immune to mind-affecting effects; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a>
20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">cold resistance</a> 20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>;
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> at will; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
500 ft.; telepathy out to 1,000 ft. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Undead Traits:</span> Immune
to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#poison">poison</a>, sleep, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#paralyzed">paralysis</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#stunned">stunning</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#disease">disease</a>, death,
effects,
necromantic effects, mind-affecting effects, and any effect requiring a
Fortitude save unless it also works on objects. Not subject to critical
hits, subdual damage, ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, ability
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>.
Negative energy heals. Not at risk of death from massive damage, but
destroyed at 0 hit points or less. Darkvision 60 ft. Cannot be raised;
resurrection works only if creature is willing.
    </p>
    <h3><a id="chichimec"></a>CHICHIMEC</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery5a/44173_C5_Chichimec.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Medium-Size </span><a style={{
        "color": "black",
        "fontWeight": "bold"
      }} href="monsterTypes.html#outsider">Outsider</a><span style={{
        "fontWeight": "bold"
      }}> (</span><a style={{
        "color": "black",
        "fontWeight": "bold"
      }} href="monsterTypes.html#air">Air</a><span style={{
        "fontWeight": "bold"
      }}>, </span><a style={{
        "color": "black",
        "fontWeight": "bold"
      }} href="monsterTypes.html#evil">Evil</a><span style={{
        "fontWeight": "bold"
      }}>, </span><a style={{
        "color": "black",
        "fontWeight": "bold"
      }} href="monsterTypes.html#extraplanar">Extraplanar</a><span style={{
        "fontWeight": "bold"
      }}>) &nbsp;&nbsp;&nbsp;</span> </p>
    <span style={{
      "fontWeight": "bold"
    }}>Hit Dice:</span> 27d8+189 (425
hp)
    <span style={{
      "fontWeight": "bold"
    }}>Initiative:</span>&nbsp; +7
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Speed:</span>&nbsp; 5 ft., fly
200 ft. (perfect) &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>AC:</span> 39 (+7 Dex, +22
natural) &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Base Attack /Grapple:</span>
+27/+39&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Attack:</span> Primary wing
buffet +39 (2d6+12) melee&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Full Attack:</span> 2 primary
wing buffets +39 (2d6+12) melee, 6 secondary wing buffets +37 (1d6+6)
melee, tail slam +37 (1d6+6 plus Cha drain) melee&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Space/Reach:</span> 5 ft./5 ft.
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Special Attacks:</span>
Spell-like abilities, summon <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersEtoF.html#air-elemental">air elemental</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#ability-score-loss">Charisma drain</a>
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Special Qualities:</span>
Abomination traits, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#fast-healing">fast healing</a> 10, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#SR">SR</a>
33, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#DR">DR</a>
10/ good and epic,
electricity immunity &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Saves:</span> Fort +22, Ref +22,
Will +17 &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Abilities:</span> Str 34, Dex
25, Con 24, Int 12, Wis 14, Cha 30 &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Skills:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#diplomacy">Diplomacy</a>
+14, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#hide">Hide</a>
+37, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#intimidate">Intimidate</a> +40, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#jump">Jump</a>
-3, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#knowledge">Knowledge</a> (religion) +31, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#knowledge">Knowledge</a>
(the planes) +31, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#listen">Listen</a> +34, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#move-silently">Move
Silently</a> +37, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#search">Search</a> +31, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#sense-motive">Sense
Motive</a> +32, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#spot">Spot</a> +34&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Feats:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#alertness">Alertness</a>,
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#dodge">Dodge</a>,
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterFeats.html#flyby-attack">Flyby Attack</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#improved-bull-rush">Improved Bull Rush</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#mobility">Mobility</a>,
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterFeats.html#multiattack">Multiattack</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#power-attack">Power
Attack</a>
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Epic Feats:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#blinding-speed">Blinding
Speed</a> (x 2), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#epic-toughness">Epic Toughness</a>&nbsp;&nbsp;&nbsp;
    <span style={{
      "fontWeight": "bold"
    }}>Climate/Terrain:</span> Any
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Organization:</span> Solitary,
pair, or flock (6-9) &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Challenge Rating:</span> 21
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Treasure:</span> None
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Alignment:</span> Always neutral
evil &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Advancement:</span> 28-38 HD
(Medium-size); 39-50 HD (Large) &nbsp;&nbsp;&nbsp; 
    <p>An chichimec&#8217;s natural weapons are treated as epic and
evil-aligned for the purpose of overcoming <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a>.</p>
    <h6>COMBAT </h6>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
Caster level 20th; save DC 20 + spell level. The DC is
Charisma-based.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#darkness">darkness</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#telekinesis">telekinesis</a>; 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>3/day</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#greater-invisibility">greater invisibility</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsC.html#control-weather">control
weather</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsC.html#call-lightning">call lightning</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#lightning-bolt">lightning
bolt</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsC.html#chain-lightning">chain lightning</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#wail-of-the-banshee">wail of the
banshee</a>. 
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon Air Elemental (Sp):</span>
Three times per day a chichimec can summon an elder <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersEtoF.html#air-elemental">air elemental</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Charisma Drain (Su):</span>
This effect permanently reduces a living opponent&#8217;s Charisma score by 2
points when the chichimec hits with a tail slam, or 4 points on a
critical hit. The chichimec heals 10 points of damage, or 20 on a
critical hit, whenever it drains Charisma, gaining any excess as
temporary hit points. These temporary hit points last a maximum of 1
hour. The attack allows a Fortitude save (DC 33)-on a successful save,
only 1 point of Charisma is drained and the chichimec heals 5 points of
damage. The DC is Charisma-based.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abomination Traits:</span>
Immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, and
other form-altering attacks;
not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, or
death
from massive damage; immune to mind-affecting effects; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a>
20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">cold resistance</a> 20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>;
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> at will; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
500 ft.; telepathy out to 1,000 ft. </p>
    <h3><a id="dream-larva"></a>DREAM LARVA</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p style={{
      "fontWeight": "bold"
    }}>Large <a style={{
        "color": "black"
      }} href="monsterTypes.html#outsider">Outsider</a>
(<a style={{
        "color": "black"
      }} href="monsterTypes.html#chaotic">Chaotic</a>,
      <a style={{
        "color": "black"
      }} href="monsterTypes.html#evil">Evil</a>, <a style={{
        "color": "black"
      }} href="monsterTypes.html#extraplanar">Extraplanar</a>)
&nbsp;&nbsp;&nbsp; </p>
    <span style={{
      "fontWeight": "bold"
    }}>Hit Dice:</span> 40d8+360 (700
hp) &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Initiative:</span> +3 (Dex)
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Speed:</span> 80 ft.; fly 240
ft. (perfect) &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>AC:</span> 52 (-1 size, +3 Dex,
+40 natural) &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Base Attack/Grapple:</span>
+40/+60&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Attack:</span> Bite +56
(4d8+16)melee&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Full Attack:</span> Bite +56
(4d8+16) melee, 1 gore +51 (4d6+8) melee, 4 pincers +53 (4d6+8) melee,
4 claws +53 (4d6+8) melee&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Space/Reach:</span> 10 ft./10
ft. &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Special Attacks:</span> Worst
nightmare, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#improved-grab">improved grab</a>, sending,
spell-like abilities, summon <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersMtoN.html#nightwalker">nightwalker</a>&nbsp;&nbsp;&nbsp;
    <span style={{
      "fontWeight": "bold"
    }}>Special Qualities:</span>
Abomination traits, sonic immunity, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#regeneration">regeneration</a>
15, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#fast-healing">fast healing</a> 15, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#SR">SR</a>
44, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#DR">DR</a>
15/ Good and Epic or Lawful and Epic &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Saves:</span> Fort +31, Ref +25,
Will +29 &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Abilities:</span> Str 42, Dex
17, Con 29, Int 16, Wis 24, Cha 36 &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Skills:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#concentration">Concentration</a>
+52, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#craft">Craft</a>
(dreamweaving) +46, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#diplomacy">Diplomacy</a> +60, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#escape-artist">Escape
Artist</a> +46, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#hide">Hide</a>
+46, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#jump">Jump</a>
+36, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#knowledge">Knowledge</a> (arcana) +46, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#listen">Listen</a>
+50, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#move-silently">Move Silently</a> +46, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#search">Search</a>
+46, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#sense-motive">Sense Motive</a> +50, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#spot">Spot</a>
+50&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Feats:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#cleave">Cleave</a>,
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#combat-reflexes">Combat
Reflexes</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterFeats.html#flyby-attack">Flyby Attack</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#great-cleave">Great
Cleave</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#improved-sunder">Improved Sunder</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#power-attack">Power
Attack</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterFeats.html#quicken-spell-like-ability">Quicken
Spell-Like Ability</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#weapon-focus">Weapon Focus</a> (pincers), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#weapon-focus">Weapon
Focus</a>
(gore), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#weapon-focus">Weapon Focus</a> (bite), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#weapon-focus">Weapon
Focus</a> (claw) &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Epic Feats:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#epic-toughness">Epic
Toughness</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a>
(pincers), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a>
(claw)&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Climate/Terrain:</span> Any
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Organization:</span> Solitary,
pair, or solitary plus 1-4 nightwalkers &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Challenge Rating:</span> 31
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Treasure:</span> Standard
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Alignment:</span> Always chaotic
evil &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Advancement:</span> 41-65 HD
(Large); 66-84 HD (Huge); 85-110 HD (Gargantuan) &nbsp;&nbsp;&nbsp; 
    <p>A dream larvas&#8217;s natural weapons are treated as epic, evil,
and chaotic-aligned for the purpose of overcoming <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a>.</p>
    <h6>COMBAT </h6>
    <p><span style={{
        "fontWeight": "bold"
      }}>Worst Nightmare (Su):</span>
Each time a living creature first views a specific dream larva from a
distance of 30 feet or less (or from a scrying effect), the subject
sees the image of the most fearsome creature imaginable. This is not an
illusion or phantasm; the dream larva truly becomes, for just that
instant, the subject&#8217;s worst nightmare. Even if simultaneously viewed
by dozens of different creatures, the dream larva appears differently
to each one of them. Creatures immune to fear or mind-affecting effects
(or warded by <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsPtoR.html#protection-from-evil">protection from evil</a>
or <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#death-ward">death ward</a> spells) are immune to
worst nightmare; all others must make a Will save (DC 43) or die from
the supernatural horror revealed. Survivors (and those resurrected) are
immune to the effect from that individual dream larva in the future.
The DC is Charisma-based.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
Caster level 31st; save DC 23 + spell level. The DC is
Charisma-based.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#fly">fly</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#haste">haste</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nightmare">nightmare</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsPtoR.html#prismatic-spray">prismatic spray</a>; 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>2/day</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicSpells.html#dreamscape">dreamscape</a>
(epic
spell). 
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Improved Grab (Ex):</span> If
the dream larva hits with a claw or pincer, it deals normal damage and
attempts to start a <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#grapple">grapple</a> as a free action
without provoking an
attack of opportunity. Dream larvae can use improved grab on a creature
of any size. The dream larva has the option to conduct the grapple
normally, simply use the claw or pincer to hold the opponent, or use
sending (see below) on the opponent. Each successful grapple check it
makes during successive rounds automatically deals the damage listed
for the attack that established the hold, unless it used sending on the
victim.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Sending (Su):</span> Grappled
victims may be physically sent into a nightmare, at the dream larva&#8217;s
option, on the dream larva&#8217;s next action after establishing the
grapple. Victims must make a Will save (DC 43) or be apparently swarmed
by thousands of worms making up the dream larva&#8217;s body. The victim is
actually cast into a shrieking maelstrom of dream where it can take no
actions but observe. The victim physically returns 2d4 rounds later,
apparently ejected from the dream larva&#8217;s body (but appearing even if
if the dream larva has left or is otherwise absent). The victim of the
nightmare has taken 4d6 points of temporary Wisdom <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, but is
otherwise free to act on the round it returns. If the victim takes more
Wisdom damage than it has points of Wisdom, the extra points are
instead treated as temporary Constitution damage. The DC is
Charisma-based.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon Nightwalker (Sp):</span>
Five times per day, a dream larva can summon a <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersMtoN.html#nightwalker">nightwalker</a>.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abomination Traits:</span>
Immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, and
other form-altering attacks;
not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, or
death
from massive damage; immune to mind-affecting effects; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a>
20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">cold resistance</a> 20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>;
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> at will; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
500 ft.; telepathy out to 1,000 ft. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Regeneration (Ex):</span>
Dream larvae take normal damage from good or lawful weapons or weapons
forged by a sleepwalking weaponsmith.
    </p>
    <h3><a id="hecatoncheires"></a>HECATONCHEIRES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Huge <a style={{
          "color": "black"
        }} href="monsterTypes.html#outsider">Outsider</a> (<a style={{
          "color": "black"
        }} href="monsterTypes.html#chaotic">Chaotic</a>, <a style={{
          "color": "black"
        }} href="monsterTypes.html#evil">Evil</a>, <a style={{
          "color": "black"
        }} href="monsterTypes.html#extraplanar">Extraplanar</a>)
&nbsp;&nbsp;&nbsp;</span> </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Dice:</span> 52d8+572
(1028 hp) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Initiative:</span> +10 (+2 Dex,
+8 Superior Initiative) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Speed:</span> 100 ft.
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>AC:</span> 70 (-2 size, +30
natural, +20 insight, +12 armor [+5 half plate]) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Base Attack/Grapple:</span>
+52/+80&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Attack:</span> Greatsword +73
(2d6+20/17-20) melee; or boulder +55 (2d8+20/19-20)
ranged&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Full Attack:</span> 100
greatswords +73 (2d6+20/17-20) melee; or 100 boulders +55
(2d8+20/19-20) ranged&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Space/Reach:</span> 15 ft./15
ft. &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Attacks:</span> Superior
multiweapon fighting, spell-like abilities, summon hecatoncheires
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Qualities:</span>
Abomination traits, electricity immunity, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#regeneration">regeneration</a>
40, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#fast-healing">fast healing</a>
50, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#SR">SR</a>
70, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">DR</a>
20/Good and Epic and Cold Iron &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Saves:</span> Fort +39, Ref +30,
Will +27 &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Abilities:</span> Str 50, Dex
15, Con 32, Int 10, Wis 8, Cha 24 &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#climb">Climb</a>
+75, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> +13, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a>
-12, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#intimidate">Intimidate</a> +40, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
+89, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a> (history)
+33, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a>
+104, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#search">Search</a>
+105, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense Motive</a> +49, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>
+104, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#survival">Survival</a>
+54&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#cleave">Cleave</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#combat-reflexes">Combat
Reflexes</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#great-cleave">Great Cleave</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-critical">Improved Critical</a>
(greatsword), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-critical">Improved Critical</a>
(boulder), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-initiative">Improved Initiative</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterFeats.html#multidexterity">Multidexterity</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterFeats.html#multiweapon-fighting">Multiweapon
Fighting</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#power-attack">Power Attack</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-sunder">Improved
Sunder</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#weapon-focus">Weapon Focus</a> (greatsword), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#weapon-focus">Weapon
Focus</a>
(boulder) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-toughness">Epic
Toughness</a> (x2), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a>
(greatsword), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a>
(boulder), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#multiweapon-rend">Multiweapon Rend</a>
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Climate/Terrain:</span> Any
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Organization:</span> Solitary or
pair &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Challenge Rating:</span> 57
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Treasure:</span> Standard
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Alignment:</span> Always chaotic
evil &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Advancement:</span> 53-58 HD
(Huge); 59-70 HD (Gargantuan); 71-140 HD (Colossal) &nbsp;&nbsp;&nbsp; 
    </p>
    <p>A hecatoncheires&#8217; natural weapons are treated as epic, evil,
and chaotic-aligned for the purpose of overcoming <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a>.</p>
    <h6>COMBAT </h6>
    <p><span style={{
        "fontWeight": "bold"
      }}>Superior Multiweapon Fighting
(Ex):</span> A hecatoncheires fights with a greatsword or a boulder in
each hand. The hecatoncheires does not suffer an attack or damage
penalty for attacking with one hundred weapons. However, the press of
limbs prevents the creature from making iterative attacks with any of
its arms, nor can it make more than ten attacks against a Small or
smaller creature, fifteen attacks against a Medium-size creature, or
twenty attacks against a Large creature in the same action (it can make
all its attacks against a Huge or larger creature in one action). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> A
hecatoncheires&#8217; fifty heads give it a +50 racial bonus on&nbsp;<a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a>,&nbsp;<a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>,
and&nbsp;<a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#search">Search</a>
checks. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
Caster level 50th; save DC
17 + spell level. The DC is Charisma-based.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#greater-magic-weapon">greater magic weapon</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#fly">fly</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#shield">shield</a>.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon Hecatoncheires (Sp):</span>
A hecatoncheires can summon one other hecatoncheires once per day,
though is loath to do so because then it will be similarly obligated to
answer its sibling&#8217;s summoning. A summoned hecatoncheires cannot use
its summoning power while &#8220;summoned.&#8221; </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abomination Traits:</span>
Immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, and
other form-altering attacks;
not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, or
death
from massive damage; immune to mind-affecting effects; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a>
20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">cold resistance</a> 20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>;
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> at will; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
500 ft.; telepathy out to 1,000 ft. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Regeneration (Ex):</span>
Hecatoncheires take normal damage from good weapons or weapons tempered
with the blood of a deity.
    </p>
    <h3><a id="infernal"></a>INFERNAL</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p style={{
      "fontWeight": "bold"
    }}>Large <a style={{
        "color": "black"
      }} href="monsterTypes.html#outsider">Outsider</a>
(<a style={{
        "color": "black"
      }} href="monsterTypes.html#evil">Evil</a>) (<a style={{
        "color": "black"
      }} href="monsterTypes.html#chaotic">Chaotic</a> or <a style={{
        "color": "black"
      }} href="monsterTypes.html#lawful">Lawful</a>)
&nbsp;&nbsp;&nbsp; </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Dice:</span> 40d8+360
(680 hp) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Initiative:</span> +11 (+7 Dex,
+4 <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-initiative">Improved Initiative</a>)
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Speed:</span> 80 ft., fly 240
ft. (perfect) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>AC:</span> 50 (+7 Dex, -1 size,
+34 natural) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Base Attack/Grapple:</span>
+40/+60&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Attack:</span> +58 claw (4d6+16)
melee&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Full Attack:</span> 2 claws +58
(4d6+16) melee, 1 bite +52 (4d8+8 +spell suck) melee, 2 wings +50
(2d6+8) melee, 1 tail slam +50 (4d8+8) melee&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Space/Reach:</span> 10ft./10 ft.
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Attacks:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#improved-grab">Improved
grab</a>, spell suck, learned spell immunity, spell-like abilities,
summon
fiend &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Qualities:</span>
Abomination traits, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#fast-healing">fast healing</a> 15, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#regeneration">regeneration</a>
15, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#SR">SR</a>
38, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">DR</a>
35/+7
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#balance">Balance</a>
+50, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a> +52, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a>
+52, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#escape-artist">Escape Artist</a> +50, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a>
+50, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
+36, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a> (arcana) +49, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a>
(the planes) +49, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> +53, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#move-silently">Move
Silently</a> +50, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#search">Search</a> +49, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense
Motive</a> +51, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a> +49, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>
+53, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#tumble">Tumble</a>
+50&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#alertness">Alertness</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#cleave">Cleave</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#great-cleave">Great Cleave</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-initiative">Improved Initiative</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#power-attack">Power
Attack</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-sunder">Improved Sunder</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#weapon-focus">Weapon
Focus</a>
(claw) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#blinding-speed">Blinding
Speed</a> (x2), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-toughness">Epic Toughness</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a> (bite), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a>
(claw), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#tenacious-magic">Tenacious Magic</a> (greater
invisibility), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#tenacious-magic">Tenacious Magic</a> (blur)
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Saves:</span> Fort +31, Ref +29,
Will +30 &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Abilities:</span> Str 43, Dex
25, Con 28, Int 22, Wis 26, Cha 29 &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Climate/Terrain:</span> Any
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Organization:</span> Solitary or
hell brigade (1 infernal and 1d4 balors, or 1 infernal and 1d4 pit
fiends) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Challenge Rating:</span> 26
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Treasure:</span> Standard
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Alignment:</span> Lawful evil or
chaotic evil &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Advancement:</span> 41-50 HD
(Large); 51-56 HD (Huge); 57-72 HD (Gargantuan) &nbsp;&nbsp;&nbsp; 
    </p>
    <p>An infernal&#8217;s natural weapons are treated as epic, evil, and
chaotic-aligned or lawful-aligned for the purpose of overcoming <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a>.</p>
    <h6>COMBAT </h6>
    <p><span style={{
        "fontWeight": "bold"
      }}>Improved Grab (Ex):</span> If
an infernal hits with a claw, it deals normal damage and attempts to
start a <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#grapple">grapple</a> as a free action
without provoking an attack of
opportunity. Infernals can use this ability on Large and smaller
creatures. The infernal has the option to conduct the grapple normally,
or simply use its claw to hold the opponent. Each successful grapple
check it makes during successive rounds automatically deals standard
claw damage, in addition to automatic spell suck damage. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell Suck (Su):</span> If an
infernal bites a foe, the foe loses one of its highest-level prepared
spells or one of the spell slots for this day that it has not used. The
victim chooses the prepared spell to lose. If the victim has no
prepared spells or unused spell slots (either because it has exhausted
its spellcasting for the day or because the victim is not a
spellcaster), the bite instead deals 2 points of temporary Intelligence
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Learned Spell Immunity (Su):</span>
If an infernal is affected by a spell cast by a particular spellcaster,
the infernal thereafter becomes immune to that spell when cast by that
spellcaster. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
Caster level 26th; save
DC 19 + spell level. The DCs are Charisma-based.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsAtoB.html#animate-dead">animate
dead</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsAtoB.html#blasphemy">blasphemy</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsAtoB.html#blur">blur</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsC.html#charm-person">charm person</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsC.html#create-undead">create
undead</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#darkness">darkness</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#deeper-darkness">deeper darkness</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#desecrate">desecrate</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-chaos">detect chaos</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-good">detect
good</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-law">detect
law</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-magic">detect magic</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#fear">fear</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#fireball">fireball</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#greater-dispel-magic">greater dispel magic</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#hold-person">hold
person</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#greater-invisibility">greater invisibility</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#magic-circle-against-good">magic circle against
good</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#major-image">major image</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsPtoR.html#produce-flame">produce
flame</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsPtoR.html#polymorph">polymorph</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsPtoR.html#pyrotechnics">pyrotechnics</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsPtoR.html#read-magic">read magic</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#scrying">scrying</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#suggestion">suggestion</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#symbol-of-death">symbol
of death</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#telekinesis">telekinesis</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#greater-teleport">greater teleport</a> (self
plus 1,000 pounds), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#unholy-aura">unholy aura</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#unholy-blight">unholy
blight</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#unhallow">unhallow</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#wall-of-fire">wall
of fire</a>; 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>1/day</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#fire-storm">fire
storm</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicSpells.html#hellball">hellball</a> (epic spell), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#implosion">implosion</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#meteor-swarm">meteor swarm</a>. 
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon Fiend (Sp):</span> An
infernal can summon four <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersDtoDe.html#balor-demon">balors</a> or four <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersDtoDe.html#pit-fiend-devil">pit fiends</a> per day
(lawful
infernals summon pit fiends, and chaotic infernals summon balors). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abomination Traits:</span>
Immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, and
other form-altering attacks;
not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, or
death
from massive damage; immune to mind-affecting effects; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a>
20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">cold resistance</a> 20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>;
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> at will; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
500 ft.; telepathy out to 1,000 ft. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Regeneration (Ex):</span>
Infernals take normal damage from good weapons. Lawful infernals also
take normal damage from chaotic weapons, and vice versa. </p>
    <h3><a id="phaethon"></a>PHAETHON</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p style={{
      "fontWeight": "bold"
    }}>Gargantuan <a style={{
        "color": "black"
      }} href="monsterTypes.html#outsider">Outsider</a>
(<a style={{
        "color": "black"
      }} href="monsterTypes.html#evil">Evil</a>, <a style={{
        "color": "black"
      }} href="monsterTypes.html#extraplanar">Extraplanar</a>,
      <a style={{
        "color": "black"
      }} href="monsterTypes.html#fire">Fire</a>)&nbsp;&nbsp;&nbsp;
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Dice:</span> 62d8+806
(1,362 hp) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Initiative:</span> +15 (+7 Dex,
+8 <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#superior-initiative">Superior Initiative</a>)
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Speed:</span> 120 ft., burrow
120 ft. &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>AC:</span> 47 (-4 size, +7 Dex,
+34 natural) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Base Attack/Grapple:</span>
+62/+98&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Attack:</span> pseudopod slam
+85 (4d8+24 plus fire damage) melee&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Full Attack:</span> 8 pseudopod
slams +85 (4d8+24 plus fire damage) melee &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Space/Reach:</span> 20 ft./10
ft. &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Attacks:</span> Fiery
touch, fiery overrun, spell-like abilities, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#improved-grab">improved grab</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#swallow-whole">swallow
whole</a>, summon <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersEtoF.html#fire-elemental">elder fire elemental</a>
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Qualities:</span>
Abomination traits, immunity to fire, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#vulnerability-to-energy">vulnerability to cold</a>,
oozelike
immunities, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#regeneration">regeneration</a> 25, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#fast-healing">fast
healing</a> 25, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#SR">SR</a> 46, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">DR</a>
15/epic lawful
and epic &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Saves:</span> Fort +48, Ref +42,
Will +43 &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Abilities:</span> Str 58, Dex
25, Con 36, Int 8, Wis 18, Cha 39 &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#climb">Climb</a>
+89, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(metalworking, stoneworking) +29, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a> +12, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
+69, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a>
(geography) +64, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> +71, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#move-silently">Move
Silently</a> +72, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#search">Search</a> +64, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>
+71&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#alertness">Alertness</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#cleave">Cleave</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#great-cleave">Great Cleave</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#great-fortitude">Great
Fortitude</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-initiative">Improved Initiative</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#iron-will">Iron
Will</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#lightning-reflexes">Lightning Reflexes</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#power-attack">Power
Attack</a>, Sunder, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#weapon-focus">Weapon Focus</a> (slam)
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#blinding-speed">Blinding
Speed</a> (x5), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-toughness">Epic Toughness</a> (x3), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a> (slam), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-will">Epic
Will</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#superior-initiative">Superior Initiative</a>
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Climate/Terrain:</span> Any
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Organization:</span> Solitary,
or in company of 1-10 elder fire elementals &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Challenge Rating:</span> 34
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Treasure:</span> Standard
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Alignment:</span> Always chaotic
evil &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Advancement:</span> 63-80 HD
(Gargantuan); 81-186 HD (Colossal) &nbsp;&nbsp;&nbsp; 
    </p>
    <p>A phaethon&#8217;s natural weapons are treated as epic and evil for
the purpose of overcoming <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage reduction</a>.</p>
    <h6>COMBAT </h6>
    <p><span style={{
        "fontWeight": "bold"
      }}>Fiery Touch (Ex):</span>
Touching or being touched by a phaethon deals 2d6 points of fire
damage. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Fiery Overrun (Ex):</span> A
foe who is successfully <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#overrun">overrun</a> by a phaethon is
treated as if
swallowed whole. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Improved Grab (Ex):</span> If
the phaethon hits with a pseudopod, it deals normal damage and attempts
to start a <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#grapple">grapple</a> as a free action
without provoking an attack of
opportunity. Phaethons can use improved grab on a Huge or smaller
creature. The phaethon has the option to conduct the grapple normally,
simply maintaining a pseudopod hold, or attempting to absorb the
opponent by swallowing the foe whole. Each successful grapple check it
makes during successive rounds automatically deals the damage listed
for the pseudopod. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Swallow Whole (Ex):</span>
The phaethon can absorb opponents it holds with a second successful <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#grapple">grapple</a>
check after a grab. The
opponent must be Huge or smaller.
Absorbed creatures take 20d6 points of fire damage and 10d6 points of
bludgeoning damage each round they remain inside a phaethon. Victims
must make a successful grapple check to &#8220;swim&#8221; free of the living magma
of the phaethon. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
Caster level 34th; save DC 24 + spell
level.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#fireball">fireball</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#fire-storm">fire storm</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#delayed-blast-fireball">delayed blast fireball</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#flame-strike">flame strike</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#wall-of-fire">wall
of fire</a>; 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>1/day</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#scrying">scrying</a>.&nbsp;
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon Elder Fire Elemental
(Sp):</span> Phaethons can summon up to ten <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersEtoF.html#fire-elemental">elder fire elementals</a> per
day. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abomination Traits:</span>
Immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, and
other form-altering attacks;
not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, or
death
from massive damage; immune to mind-affecting effects; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a>
20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">cold resistance</a> 20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>;
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> at will; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
500 ft.; telepathy out to 1,000 ft. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Oozelike Immunities (Ex):</span>
Immune to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#poison">poison</a>, sleep, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#paralyzed">paralysis</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#stunned">stunning</a>, polymorphing,
and
mind-influencing effects; not subject to critical hits or flanking; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#blinded">blind</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Regeneration (Ex):</span>
Phaethons take normal damage from lawful weapons and cold-forged
weapons, and double damage from cold or ice weapons.
    </p>
    <h3>PHANE<a id="phane"></a></h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p style={{
      "fontWeight": "bold"
    }}>Large <a style={{
        "color": "black"
      }} href="monsterTypes.html#outsider">Outsider</a>
(<a style={{
        "color": "black"
      }} href="monsterTypes.html#chaotic">Chaotic</a>,
      <a style={{
        "color": "black"
      }} href="monsterTypes.html#evil">Evil</a>, <a style={{
        "color": "black"
      }} href="monsterTypes.html#extraplanar">Extraplanar</a>,
      <a style={{
        "color": "black"
      }} href="monsterTypes.html#incorporeal">Incorporeal</a>)
&nbsp;&nbsp;&nbsp; </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Dice:</span> 36d8+324
(612 hp) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Initiative:</span> +11 (+7 Dex,
+4 <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-initiative">Improved Initiative</a>)
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Speed:</span> 80 ft., fly 120
ft. (perfect) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>AC:</span> 50 (-1 size, +7 Dex,
+11 deflection, +23 insight) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Base Attack/Grapple:</span>
+36/&shy;&shy;-&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Attack:</span> Incorporeal touch
+43 (1d6 plus stasis touch) melee&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Full Attack:</span> Incorporeal
touch +43 (1d6 plus stasis touch) melee&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Space/Reach:</span> 10 ft./10
ft. &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Attacks:</span>
Spell-like abilities, stasis touch, chronal blast, time leach, summon
past time duplicate &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Special Qualities:</span>
Abomination traits, null time field, time regression, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#fast-healing">fast
healing</a> 15, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#regeneration">regeneration</a> 15, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#SR">SR</a>
37, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">DR</a>
15/epic, sonic immunity &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Saves:</span> Fort +31, Ref +29,
Will +29 &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Abilities:</span> Str -, Dex 25,
Con 28, Int 24, Wis 16, Cha 33 &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#balance">Balance</a>
+46, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a> +48, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a>
+50, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a>
+42, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
+20, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a>
(arcana, religion, the planes) +46, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a>
+44, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#move-silently">Move Silently</a> +46, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#search">Search</a>
+46, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense Motive</a> +42, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a>
+46, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>
+44, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#swim">Swim</a>
+39, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#tumble">Tumble</a>
+46&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#alertness">Alertness</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#combat-reflexes">Combat
Reflexes</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#dodge">Dodge</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#great-fortitude">Great
Fortitude</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#improved-initiative">Improved Initiative</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#iron-will">Iron
Will</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#lightning-reflexes">Lightning Reflexes</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="featsAll.html#weapon-focus">Weapon
Focus</a> (incorporeal touch) &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span> <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#blinding-speed">Blinding
Speed</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-toughness">Epic Toughness</a> (x2), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#epic-will">Epic
Will</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicFeats.html#spell-stowaway">Spell Stowaway</a> (time
stop)&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Climate/Terrain:</span> Any land
and underground &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Organization:</span> Solitary or
pair &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Challenge Rating:</span> 25
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Treasure:</span> None
&nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Alignment:</span> Always chaotic
evil &nbsp;&nbsp;&nbsp; 
      <span style={{
        "fontWeight": "bold"
      }}>Advancement:</span> 37-50 HD
(Large); 51-65 HD (Huge)</p>
    <p>A phanes&#8217; natural weapons are treated as epic, evil, and
chaotic-aligned for the purpose of overcoming <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a>.</p>
    <h6>COMBAT</h6>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
Caster level 21st; save DC 21 + spell level. The DC is
Charisma-based.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-good">detect
good</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-magic">detect magic</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsFtoG.html#greater-invisibility">greater invisibility</a>
(self only); 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>5/day</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#haste">haste</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#slow">slow</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#greater-teleport">greater teleport</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#tongues">tongues</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#trap-the-soul">trap the soul</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-strike">true
strike</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#unholy-aura">unholy aura</a>; 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>2/day</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#safe-time">safe
time</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicSpells.html#time-duplicate">time duplicate</a> (epic spell);
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>1/day</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#time-stop">time
stop</a>. 
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Stasis Touch (Su):</span> As <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#temporal-stasis">temporal stasis</a>, except as
an at-will supernatural ability. Caster
level 25th; save DC 21 + spell level. The DC is Charisma-based.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Chronal Blast (Su):</span> As
a standard action, the phane can make a ranged touch attack against any
creature within 100 feet. If it succeeds, the subject is targeted by a
spasm of space-time flux, dealing 15d6 points of damage. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Time Leach (Su):</span> For
every round of apparent time experienced by the phane, it automatically
absorbs the &#8220;future&#8221; from any creature it has successfully encapsulated
in static time via its stasis touch (not its null time field), no
matter the distance separating victim and phane, and no matter the
number of victims. Of course, to the victim no time passes at all, but
each apparent round experienced by the phane ages the victim 1d4 years,
at the same time healing the phane of 20 hit points of damage. A victim
who is not somehow released from static time by a friend who can cast <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#dispel-magic">dispel
magic</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#greater-dispel-magic">greater dispel magic</a>,
or some other likely spell,
eventually ages to death. Victims killed in this manner automatically
fall out of static time as desiccated husks that disintegrate to a fine
dust with even the lightest touch. Victims who are released prior to
death immediately apply the physical effects of aging, but not the
mental effects. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon Past Time Duplicate
(Sp):</span> Once per day, a phane can summon a duplicate of one its
foes stolen from a parallel alternate past. The stolen time duplicate
has the same stats and possessions as the original, but is treated as
if having two <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drain">negative levels</a>
(which simulates a less experienced
version of the original). The phane can never summon a past time
duplicate of a creature with more than 25 HD (add sufficient negative
levels to compensate for high foe HD, if necessary). The past time
duplicate, despite having most of the knowledge of the original, serves
the phane loyally like any summoned creature. If the past time
duplicate is slain, the original is not harmed because the duplicate
was pulled from a parallel past. However, the original does not
necessarily realize this, and must make a Will save (DC 30) or be <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#shaken">shaken</a> for 1d4 rounds
after witnessing the death of a duplicate for the
first time. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abomination Traits:</span>
Immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, and
other form-altering attacks;
not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, or
death
from massive damage; immune to mind-affecting effects; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a>
20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">cold resistance</a> 20; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>;
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> at will; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
500 ft.; telepathy out to 1,000 ft. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Null Time Field (Ex):</span>
Phanes continually generate a 30-foot-radius spread null time field.
All creatures and objects in the field, except the phane, must make a
Will saving throw (DC 30) each round to take any actions. On a failed
save, subjects are stuck in a static time stream until their next round
of actions, at which time they must make another saving throw. While a
subject is stuck in a static time stream induced by a null time field,
the phane can use its static touch on the subject, though in all other
ways, the subject is invulnerable to attacks and damage as if in <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#temporal-stasis">temporal stasis</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Time Regression (Su):</span>
If the phane spends an action per round for four rounds, at the end of
the 4th round the phane regresses back in time 4 rounds, to the very
1st round it originally began concentrating on time regression. On its
second pass through the time stream, it can take completely different
actions, based on its knowledge of the future (though if it takes
different actions from its first pass through the time stream, the
events of the original time stream are also changed). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Regeneration (Ex):</span>
Phanes take normal damage from holy and good weapons, and from weapons
forged in the future or an alternate reality&#8217;s time stream (if any).
    </p>
    <h3><a id="xixecal"></a>XIXECAL</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p style={{
      "fontWeight": "bold"
    }}><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery5a/44168_C5_Xixecal.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a>Colossal <a style={{
        "color": "black"
      }} href="monsterTypes.html#outsider">Outsider</a>
(<a style={{
        "color": "black"
      }} href="monsterTypes.html#chaotic">Chaotic</a>,
      <a style={{
        "color": "black"
      }} href="monsterTypes.html#cold">Cold</a>, <a style={{
        "color": "black"
      }} href="monsterTypes.html#evil">Evil</a>, <a style={{
        "color": "black"
      }} href="monsterTypes.html#extraplanar">Extraplanar</a>)
&nbsp;&nbsp;&nbsp; </p>
    <span style={{
      "fontWeight": "bold"
    }}>Hit Dice:</span> 72d8+1,080
(1,676 hp) &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Initiative:</span> +11 (+7 Dex.
+4 <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#improved-initiative">Improved Initiative</a>)
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Speed:</span> 140 ft.
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>AC:</span> 58 (-8 size, +1 Dex,
+55 natural) &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Base Attack/Grapple:</span>
+72/+116&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Attack:</span> Claw +95
(2d8+28/19-20) melee&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Full Attack:</span> 2 claws +95
(2d8+28/19-20) melee, bite +89 (4d6+14) melee, stamp +89 (4d6+14) melee
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Space/Reach:</span> 50 ft./50
ft. &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Special Attacks:</span> Rend
4d8+42, cold, spell-like abilities, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#breath-weapon">breath weapon</a>, summon <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersDrtoDw.html#white-dragon">white dragon</a>,
Constitution <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#ability-score-loss">drain</a>
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Special Qualities:</span>
Abomination traits, cold immunity, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#vulnerability-to-energy">fire vulnerability</a>,
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#dire-winter">dire winter</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#fast-healing">fast
healing</a> 30, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#regeneration">regeneration</a>
30, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#SR">SR</a>
48, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#DR">DR</a>
20/good and epic and
adamantine &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Saves:</span> Fort +55, Ref +47,
Will +39 &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Abilities:</span> Str 66, Dex
13, Con 40, Int 12, Wis 8, Cha 34 &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Skills:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#climb">Climb</a>
+103, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#concentration">Concentration</a> +90, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#hide">Hide</a>
-9, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#jump">Jump</a>
+28, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#knowledge">Knowledge</a> (arcana, religion, the
planes) +76, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#listen">Listen</a> +70, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#search">Search</a>
+76, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#spellcraft">Spellcraft</a> +76, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#spot">Spot</a>
+76&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Feats:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#alertness">Alertness</a>,
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#cleave">Cleave</a>,
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#dodge">Dodge</a>,
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#great-cleave">Great Cleave</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#great-fortitude">Great
Fortitude</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#improved-critical">Improved Critical</a> (claw), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#improved-initiative">Improved Initiative</a>,&nbsp;<a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#improved-sunder">Improved
Sunder</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#iron-will">Iron Will</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#lightning-reflexes">Lightning Reflexes</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#power-attack">Power
Attack</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#weapon-focus">Weapon Focus</a> (bite), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#weapon-focus">Weapon
Focus</a> (claw), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#weapon-focus">Weapon Focus</a>
(stomp)&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Epic Feats:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#blinding-speed">Blinding
Speed</a> (x4), <span style={{
      "fontWeight": "bold"
    }}></span><a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#devastating-critical">Devastating
Critical</a> (claw), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#epic-toughness">Epic Toughness</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a> (bite), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a> (claw), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#epic-weapon-focus">Epic Weapon Focus</a> (stomp),
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#overwhelming-critical">Overwhelming Critical</a>
(claw), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="epicFeats.html#spell-stowaway">Spell Stowaway</a> (greater
invisibility)
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Climate/Terrain:</span>&nbsp;
Any &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Organization: </span>Solitary
or in the company of 1d4+1 old white dragons &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Challenge Rating:</span> 36
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Treasure:</span> Standard
&nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Alignment:</span> Always chaotic
evil &nbsp;&nbsp;&nbsp; 
    <span style={{
      "fontWeight": "bold"
    }}>Advancement:</span> 73-150 HD
(Colossal) &nbsp;&nbsp;&nbsp; 
    <p>A xixecal&#8217;s natural weapons are treated as epic, evil, and
chaotic-aligned for the purpose of overcoming <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#DR">damage
reduction</a>.</p>
    <h6>COMBAT </h6>
    <p><span style={{
        "fontWeight": "bold"
      }}>Rend (Ex):</span> If the
xixecal hits with both claw attacks, it latches onto the opponent&#8217;s
body and tears the flesh. This attack automatically deals an additional
4d8+42 points of damage. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Cold (Su):</span> A hit from
a xixecal&#8217;s melee attack induces numbing cold. The opponent must
succeed at a Fortitude save (DC 58) or be affected as though by a <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#slow">slow</a>
spell for 10 rounds. The DC is Charisma-based.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spell-Like Abilities:</span>
Caster level 36th; save DC 22 +
spell level. The DC is Charisma-based.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsAtoB.html#blasphemy">blasphemy</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsC.html#cone-of-cold">cone of cold</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#desecrate">desecrate</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#greater-dispel-magic">greater dispel magic</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#dominate-monster">dominate monster</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#hold-person">hold
person</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#greater-invisibility">greater invisibility</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#unholy-aura">unholy
aura</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#wall-of-ice">wall
of ice</a>; 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>5/day</span> - <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsHtoL.html#haste">haste</a>,
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#meteor-swarm">meteor swarm</a>. 
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Breath Weapon (Su):</span>
Cone of cold, 300 feet, 3/day, damage 12d6, Fort half DC 61. The DC is
Constitution-based.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon White Dragon (Sp):</span>
Five times per day a xixecal can summon an old <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersDrtoDw.html#white-dragon">white dragon</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Constitution Drain (Su):</span>
This effect permanently reduces a living opponent&#8217;s Constitution score
by 4 when the creature hits with a slam, bite, or rend, or twice that
amount on a rend or critical hit. The creature heals 20 points of
damage (or 40 on a rend or critical hit) whenever it drains
Constitution, gaining any excess as temporary hit points. These
temporary hit points last a maximum of 1 hour. The attack allows a
Fortitude save (DC 58). The DC is Charisma-based.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Abomination Traits:</span>
Immune to polymorphing, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#petrified">petrification</a>, and
other form-altering attacks;
not subject to <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>,
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-drained">drain</a>, or
ability <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#ability-damaged">damage</a>, or
death
from massive damage; immune to mind-affecting effects; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#nondetection">nondetection</a>;
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> at will; <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#blindsight">blindsight</a>
500 ft.; telepathy out to 1,000 ft.</p>
    <p>The standard <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#resistance-to-energy">fire resistance</a> 20
most abominations have is not
shared by xixecales</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Dire Winter (Su):</span> A
xixecal always stands at the center of a permanent <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="epicSpells.html#dire-winter">dire
winter</a> (epic
spell) spell effect. If <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#dispel-magic">dispelled</a>, the effect returns
1 minute later-
the only way to permanently remove the effect is to slay the xixecal
that generates it. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Regeneration(Ex):</span>
Xixecals take normal damage from Lawful weapons, and double damage from
burning or fiery weapons. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      